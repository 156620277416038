export const AUTH_USER_PROJECT_PRIVILEGES = [
  'recipe',
  'recipe.read',
  'recipe.create',
  'recipe.update',
  'recipe.delete',
  'recipe.run',
  'recipe.read_run_history',
  'folder',
  'folder.read',
  'folder.create',
  'folder.update',
  'folder.delete',
  'connection',
  'connection.read',
  'connection.create',
  'connection.update',
  'connection.delete',
  'debug_trace',
  'debug_trace.read',
  'debug_trace.create',
  'project_secret_sources',
  'project_secret_sources.read',
  'project_secret_sources.update',
  'workato_db_table',
  'workato_db_table.read',
  'workato_db_table.create',
  'workato_db_table.update_records',
  'workato_db_table.update_schema',
  'workato_db_table.delete',
  'project_property',
  'project_property.read',
  'project_property.create',
  'project_property.update_records',
  'project_property.delete',
  'test_automation',
  'test_automation.read',
  'test_automation.manage_test_cases',
  'lcap',
  'lcap.read',
  'lcap.create',
  'lcap.update',
  'lcap.delete',
  'lcap_access_management.lcap_manage_access',
  'lcap_access_management.lcap_go_live',
  'deployments',
  'deployments.deploy',
  'deployments.review',
  'deployments.assign_reviewers',
  'deployments.read_own',
  'deployments.read_all',
  'deployments.create',
  'project_administration.access_control',
] as const;

export const NEW_TO_OLD_PERMISSION_MAP = {
  project_management: 'project',
  'project_management.read': 'project.read',
  'project_management.create': 'project.create',
  'project_management.update': 'project.update',
  'project_management.delete': 'project.delete',
  'manage_projects.access_control': 'team',
} as const;
