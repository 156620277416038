import {Pipe, PipeTransform} from '@angular/core';

import {AuthUser, AuthUserPrivileges} from '../services/auth-user';
import {AuthUserPrivilegeExpression, EnvAuthUserPrivilege, ProjectAuthUserPrivilege} from '../services/auth-user.types';
import {Project} from '../modules/folders/folders.types';

@Pipe({
  name: 'wHasPermission',
})
export class HasPermissionPipe implements PipeTransform {
  constructor(private authUser: AuthUser) {}

  transform(value: AuthUserPrivileges<EnvAuthUserPrivilege>, hasAccess?: boolean): boolean;
  transform(value: AuthUserPrivileges, projectId: Project['id'] | null, hasAccess?: boolean): boolean;

  transform(
    value: AuthUserPrivileges<ProjectAuthUserPrivilege> | AuthUserPrivileges,
    projectIdOrHasAccess?: Project['id'] | null | boolean,
    hasAccess?: boolean,
  ): boolean {
    if (typeof projectIdOrHasAccess === 'boolean' || projectIdOrHasAccess === undefined) {
      return this.checkPrivileges(value, null, projectIdOrHasAccess ?? true);
    }

    return this.checkPrivileges(value, projectIdOrHasAccess, hasAccess ?? true);
  }

  private checkPrivileges(value: AuthUserPrivileges, projectId: Project['id'] | null, hasAccess = true): boolean {
    if (hasAccess) {
      return this.authUser.hasPrivilege(value, projectId);
    } else {
      const expression = this.invertExpression(this.authUser.convertToPrivilegeExpression(value));

      return !this.authUser.hasPrivilege(expression, projectId);
    }
  }

  private invertExpression<TType extends string>({
    operator,
    operands,
  }: AuthUserPrivilegeExpression<TType>): AuthUserPrivilegeExpression<TType> {
    return {
      operator: operator === 'and' ? 'or' : 'and',
      operands: operands.map(operand =>
        typeof operand === 'string' ? operand : this.invertExpression<TType>(operand),
      ),
    };
  }
}
