import {Injectable} from '@angular/core';

import {MixpanelService} from '@shared/services/mixpanel';

import {Adapter} from '../types';

import {AuthUser} from './auth-user';
import {EnvAuthUserPrivilege} from './auth-user.types';
import {Feature} from './profile.types';

export const AI_PRIVILEGES: EnvAuthUserPrivilege[] = [
  'ai_helper_sdk.all',
  'ai_helper_recipe.all',
  'ai_helper_connector.all',
];
export const AI_FEATURES: Array<Feature['name']> = [
  'ai_helper_sdk_feature',
  'ai_helper_recipe_feature',
  'ai_helper_connector_feature',
];
export const AD_HOC_AI_ADAPTER: Adapter['name'] = 'open_ai_utility';
export const AD_HOC_IDP_ADAPTER: Adapter['name'] = 'workato_idp';
export const AD_HOC_RAG_ADAPTER: Adapter['name'] = 'workato_rag';

@Injectable({
  providedIn: 'root',
})
export class CopilotHelper {
  constructor(
    private authUser: AuthUser,
    private mixpanelService: MixpanelService,
  ) {}

  get isRecipeCopilotEnabled(): boolean {
    return this.authUser.hasPrivilege('ai_helper_recipe.all') && this.hasUserConsent;
  }

  get isSDKCopilotEnabled(): boolean {
    return this.authUser.hasPrivilege('ai_helper_sdk.all') && this.hasUserConsent;
  }

  get hasUserConsent(): boolean {
    return this.authUser.coworker_enabled;
  }

  get hasSomeAiFeaturesEnabled(): boolean {
    return (
      AI_PRIVILEGES.some(feature => this.authUser.hasPrivilege(feature)) ||
      this.authUser.coworker_enabled ||
      this.authUser.hasAdHocAdapter(AD_HOC_AI_ADAPTER) ||
      this.authUser.hasAdHocAdapter(AD_HOC_IDP_ADAPTER) ||
      this.authUser.hasAdHocAdapter(AD_HOC_RAG_ADAPTER)
    );
  }

  setAiMixpanelProperties() {
    this.mixpanelService.peopleSet({
      recipe_copilot_enabled: this.authUser.hasPrivilege('ai_helper_recipe.all'),
      sdk_copilot_enabled: this.authUser.hasPrivilege('ai_helper_sdk.all'),
      copilot_consent: this.authUser.coworker_enabled,
      ai_connector_enabled: this.authUser.hasPrivilege('ai_helper_connector.all'),
      ai_connector_consent: this.authUser.hasAdHocAdapter(AD_HOC_AI_ADAPTER),
      idp_connector_enabled: this.authUser.hasPrivilege('ai_helper_idp.all'),
      idp_connector_consent: this.authUser.hasAdHocAdapter(AD_HOC_IDP_ADAPTER),
      rag_connector_enabled: this.authUser.hasPrivilege('ai_helper_rag.all'),
      rag_connector_consent: this.authUser.hasAdHocAdapter(AD_HOC_RAG_ADAPTER),
    });
  }
}
