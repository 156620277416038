import {SortOrder} from '../../types';
import {SelectFilterOption} from '../filters/filters.types';
import {DefaultOption} from '../select/select.types';

import {ROW_ID_KEY, UNSORTABLE_COLUMN_TYPES} from './data-table.constants';

export type DataTableColumnType = DataTablePrimitiveColumnType | DataTableSetColumnType;
export type DataTablePrimitiveColumnType =
  | 'number'
  | 'integer'
  | 'date'
  | 'date-time'
  | 'boolean'
  | 'short-text'
  | 'long-text'
  | 'file'
  | 'relation';

export type DataTableSetPostfix = '-set';

export const TEXT_LIKE_TYPES = ['short-text', 'long-text'] satisfies DataTableColumnType[];
export const NUMBER_LIKE_TYPES = ['number', 'integer'] satisfies DataTableColumnType[];
export const NUMBER_SET_LIKE_TYPES = ['number-set', 'integer-set'] satisfies DataTableColumnType[];
export const DATE_LIKE_TYPES = ['date', 'date-time'] satisfies DataTableColumnType[];
export const DATE_LIKE_SET_TYPES = ['date-set', 'date-time-set'] satisfies DataTableColumnType[];
export const TEXT_SET_TYPES = ['short-text-set'] satisfies DataTableColumnType[];
export const RELATION_SET_TYPES = ['relation-set'] satisfies DataTableColumnType[];

export type DataTableSetColumnType =
  `${Extract<DataTablePrimitiveColumnType, 'short-text' | 'integer' | 'number' | 'date' | 'date-time' | 'relation'>}${DataTableSetPostfix}`;
export type DataTableTextLikeColumnType = (typeof TEXT_LIKE_TYPES)[number];
export type DataTableNumberLikeColumnType = (typeof NUMBER_LIKE_TYPES)[number];
export type DataTableNumberSetLikeColumnType = (typeof NUMBER_SET_LIKE_TYPES)[number];
export type DataTableTextSetLikeColumnType = (typeof TEXT_SET_TYPES)[number];
export type DataTableDateLikeColumnType = (typeof DATE_LIKE_TYPES)[number];
export type DataTableDateLikeSetColumnType = (typeof DATE_LIKE_SET_TYPES)[number];
export type DataTableRelationSetColumnType = (typeof RELATION_SET_TYPES)[number];
export interface DataTableInvalidRelation {
  record_id: string;
  invalid: true;
}

export interface DataTableValidRelation {
  record_id: string;
  value: string | null;
}

export type DataTableRelation = DataTableValidRelation | DataTableInvalidRelation;

export type DataTableFileScanScheduledStatus = 'to_scan';
// Scan can be skipped if the file passes the antivirus settings criteria
export type DataTableFileScanSkippedStatus = 'not_to_scan';
export type DataTableFileScanSuccessStatus = 'scanned' | DataTableFileScanSkippedStatus;
export type DataTableFileScanIssueStatus = 'malicious' | 'failed' | 'limits_exceeded';
export type DataTableFileScanDoneStatus = DataTableFileScanSuccessStatus | DataTableFileScanIssueStatus;
export type DataTableFileScanStatus = DataTableFileScanScheduledStatus | DataTableFileScanDoneStatus;

export interface DataTableFileMetadata {
  id: string;
  path: string;
  size: number;
  expires_at: string;
  created_at: string;
  modified_at: string;
  av_meta: {
    state: {
      status: DataTableFileScanStatus;
      last_scan_at: string;
    };
  };
}

export interface DataTableInvalidFile {
  filename: string;
  status: DataTableFileScanIssueStatus;
}

export interface DataTableValidFile {
  filename: string;
  files_storage_path: string;
}

export type DataTableFile = DataTableValidFile | DataTableInvalidFile;

export type DataTablePrimitiveCellValue =
  | DataTableFile
  | DataTableRelation
  | string
  | number
  | boolean
  | null
  | undefined;

export type DataTableSetCellValue = null | string[] | number[] | DataTableRelation[];

export type DataTableCellValue = DataTableSetCellValue | DataTablePrimitiveCellValue;

interface DataTableColumnOf<TColumnType extends DataTableColumnType, TValueType extends DataTableCellValue = null> {
  id: string;
  title: string;
  type: TColumnType;
  default_value?: TValueType | null;
  // Whether this column is not _initially_ shown in the table. Is overridden by user table config from localStorage.
  hidden?: boolean;
  read_only?: boolean;
  required?: boolean;
  hint?: string;
}

export interface DataTable<TColumn extends DataTableColumn = DataTableColumn> {
  id: number;
  table_id: string;
  name: string;
  folder_id: number;
  project_id: string;
  total_entries_count: number;
  schema: TColumn[];
  created_at: string;
  updated_at?: string;
  deleted_at?: string;
}

export interface DataTableRow {
  [ROW_ID_KEY]: string;
  [columnId: string]: DataTableCellValue;
}

export interface DataTableRowsResult {
  records: DataTableRow[];
  continuation_token: string | null;
}

export interface DataTableSorting {
  columnId: DataTableColumn['id'];
  order: SortOrder;
}

export type DataTableConditionOperator = 'eq' | 'ne' | 'lt' | 'lte' | 'gt' | 'gte' | 'starts_with';

export type DataTableConditionValue = Exclude<DataTableCellValue, undefined | DataTableFile | DataTableRelation>;

export interface DataTableQueryCondition {
  field: DataTableColumn['id'];
  op: DataTableConditionOperator;
  value: DataTableConditionValue;
}

export interface DataTableQueryFilter {
  conditions: DataTableQueryCondition[];
}

export interface DataTableColumnRelationRef {
  table_id: string;
  field_id: string;
}

export type DataTableShortTextColumn = DataTableColumnOf<'short-text', string>;
export type DataTableShortTextSetColumn = DataTableColumnOf<'short-text-set'>;
export type DataTableLongTextColumn = DataTableColumnOf<'long-text', string>;
export type DataTableBooleanColumn = DataTableColumnOf<'boolean', boolean>;
export type DataTableIntegerColumn = DataTableColumnOf<'integer', number>;
export type DataTableIntegerSetColumn = DataTableColumnOf<'integer-set'>;
export type DataTableNumberColumn = DataTableColumnOf<'number', number>;
export type DataTableNumberSetColumn = DataTableColumnOf<'number-set'>;
export type DataTableFileColumn = DataTableColumnOf<'file', DataTableFile>;
export type DataTableDateColumn = DataTableColumnOf<'date', string>;
export type DataTableDateSetColumn = DataTableColumnOf<'date-set'>;
export type DataTableDateTimeColumn = DataTableColumnOf<'date-time', string>;
export type DataTableDateTimeSetColumn = DataTableColumnOf<'date-time-set'>;
export type DataTableRelationColumn = DataTableColumnOf<'relation', DataTableRelation> & {
  relation: DataTableColumnRelationRef;
};
export type DataTableRelationSetColumn = DataTableColumnOf<'relation-set', DataTableRelation> & {
  relation: DataTableColumnRelationRef;
};

type DataTableAnyColumn =
  | DataTableShortTextColumn
  | DataTableShortTextSetColumn
  | DataTableLongTextColumn
  | DataTableBooleanColumn
  | DataTableIntegerColumn
  | DataTableIntegerSetColumn
  | DataTableNumberColumn
  | DataTableNumberSetColumn
  | DataTableFileColumn
  | DataTableDateColumn
  | DataTableDateSetColumn
  | DataTableDateTimeColumn
  | DataTableDateTimeSetColumn
  | DataTableRelationColumn
  | DataTableRelationSetColumn;

export type DataTableColumn<TColumnType extends DataTableColumnType = DataTableColumnType> = Extract<
  DataTableAnyColumn,
  Pick<DataTableColumnOf<TColumnType, string>, 'type'>
>;

export type DataTableColumnFilterOption = SelectFilterOption<DataTableColumn['id']> & DataTableColumn;
export type DataTableUnsortableColumnType = (typeof UNSORTABLE_COLUMN_TYPES)[number];

export type DataTableMeta = Omit<DataTable, 'schema'>;

export type DataTableRowId = DataTableRow[typeof ROW_ID_KEY];

export type DataTableEmptyState = 'no-columns' | 'hidden-columns' | 'hidden-rows';

export interface DataTableReorderEvent {
  previousIndex: number;
  currentIndex: number;
}

export interface DataTableCellChangeEvent {
  rowId: DataTableRowId;
  columnId: DataTableColumn['id'];
  value: DataTableCellValue;
}

export interface DataTableColumnAddEvent {
  column: DataTableColumn;
  position: number;
}

export interface DataTableRowAddEvent {
  row: DataTableRow;
  position: number;
}

export interface DataTableDropPreview {
  initialWidth: number;
  initialIndex: number;
  columnId: DataTableColumn['id'];
  positionShift: number;
  width: number | null;
}

export interface DataTableRowErrors {
  [key: DataTableColumn['id']]: string;
}

export interface DataTableVerticalDimensions {
  height: number;
  innerHeight: number;
  headerHeight: number;
  footerHeight: number;
}

export interface DataTableRelationQuery {
  title: string;
  value: DataTableValidRelation['record_id'];
  relation: DataTableValidRelation;
}

export type DataTableRelationOption = DefaultOption<DataTableValidRelation['record_id']> &
  Omit<DataTableRelationQuery, 'relation'> & {
    error?: 'record_without_value' | 'record_not_found' | null;
  };

export interface DataTableConfiguration<TRequestContext extends object = object> {
  // Any additional context, which will be passed into DataTableService requests
  requestContext?: TRequestContext;
  emptyValueFormat?: string;
  valueFormatters?: {
    [columnType in DataTableColumnType]?: (value: DataTableCellValue) => string;
  };
}

export type ColumnTypeIcons = {
  [key in DataTableColumnType | 'set']: SvgIcon;
};
