import {CssTheme, CssVariableRef} from '../theme-types';
import {Color} from '../colors';

export const DARK_THEME: CssTheme = {
  'backdrop-default': Color['$N800-D'],
  'backdrop-landing': Color['$BR-N400'],
  'backdrop-dev': Color.$DEV800,
  'backdrop-test': Color.$TEST800,
  'backdrop-prod': Color.$PROD800,
  'backdrop-non-env': Color.$N800,
  'surface-default': Color['$N750-D'],
  'surface-elevated': Color['$N700-D'],
  'surface-field': Color['$N800-D'],
  'surface-subtle': Color['$N600-D'],
  'surface-alt': Color['$N500-D'],
  'surface-inverse': Color.$N00,
  'text-primary': Color.$N075,
  'text-secondary': Color.$N200,
  'text-emphasis': Color.$N00,
  'text-inverse': Color['$N750-D'],
  'link-default': Color.$B300,
  'link-emphasis': Color.$B200,
  'icon-default': new CssVariableRef('text-secondary'),
  'icon-emphasis': new CssVariableRef('text-emphasis'),
  'icon-inverse': new CssVariableRef('text-inverse'),
  'border-default': Color['$N400-D'],
  'border-subtle': Color['$N500-D'],
  'border-inverse': new CssVariableRef('text-inverse'),
  'brand-default': Color['$BR-T100'],
  'brand-emphasis': Color['$BR-T050'],
  'fg-onBrand': Color['$BR-N400'],
  'backdrop-nav': Color['$N750-D'],
  'nav-secondary': Color['$N700-D'],
  'nav-active': Color['$N700-D'],
  'nav-fg-primary': Color.$N075,
  'nav-fg-secondary': Color.$N200,
  'nav-fg-active': Color.$N00,
  'nav-border': Color.$N300,
  'nav-inverse': new CssVariableRef('nav-fg-primary'),

  'neutral-light': Color.$N050,
  'neutral-default': Color.$N200,
  'neutral-emphasis': Color.$N100,
  'neutral-bold': Color.$N400,
  'neutral-strong': Color['$N400-D'],
  'neutral-tint': Color['$N700-D'],
  'neutral-subtle': Color['$N400-D'],
  'neutral-muted': Color.$N400,
  'neutral-dark': Color['$N900-D'],
  'neutral-fg': new CssVariableRef('neutral-default'),
  'neutral-fg-emphasis': new CssVariableRef('neutral-emphasis'),
  'fg-onNeutral': Color.$N800,
  'fg-onNeutral-light': Color['$N900-D'],
  'fg-onNeutral-dark': Color.$N00,
  'neutral-border': new CssVariableRef('neutral-muted'),
  'neutral-border-emphasis': new CssVariableRef('neutral-emphasis'),

  'accent-default': Color.$T300,
  'accent-emphasis': Color.$T200,
  'accent-tint': Color['$T800-D'],
  'accent-subtle': Color['$T700-D'],
  'accent-muted': Color.$T500,
  'accent-fg': new CssVariableRef('accent-default'),
  'accent-fg-emphasis': new CssVariableRef('accent-emphasis'),
  'fg-onAccent': Color.$N800,
  'accent-border': new CssVariableRef('accent-muted'),
  'accent-border-emphasis': new CssVariableRef('accent-emphasis'),

  'edit-default': Color.$T300,
  'edit-emphasis': Color.$T200,
  'edit-bold': Color.$T500,
  'edit-tint': Color['$T800-D'],
  'edit-subtle': Color['$T700-D'],
  'edit-muted': Color.$T500,
  'edit-dark': Color.$T700,
  'edit-fg': new CssVariableRef('edit-default'),
  'edit-fg-emphasis': new CssVariableRef('edit-emphasis'),
  'edit-toggle-bg': Color['$N900-D'],
  'fg-onEdit': Color.$N800,
  'fg-onEdit-dark': Color.$N00,
  'fg-onEdit-toggle': Color.$N00,
  'edit-border': new CssVariableRef('edit-muted'),
  'edit-border-emphasis': new CssVariableRef('edit-emphasis'),

  'test-default': Color.$N100,
  'test-emphasis': Color.$N050,
  'test-bold': Color.$N400,
  'test-tint': Color['$N700-D'],
  'test-subtle': Color['$N400-D'],
  'test-muted': Color.$N400,
  'test-dark': Color['$N400-D'],
  'test-fg': new CssVariableRef('test-default'),
  'test-fg-emphasis': new CssVariableRef('test-emphasis'),
  'test-toggle-bg': Color['$N900-D'],
  'fg-onTest': Color.$N800,
  'fg-onTest-dark': Color.$N00,
  'fg-onTest-toggle': Color.$N00,
  'test-border': new CssVariableRef('test-muted'),
  'test-border-emphasis': new CssVariableRef('test-emphasis'),

  'test-alt-default': Color.$IN300,
  'test-alt-emphasis': Color.$IN200,
  'test-alt-tint': Color.$IN800,
  'test-alt-subtle': Color.$IN700,
  'test-alt-muted': Color.$IN500,
  'test-alt-bold': Color.$IN500,
  'test-alt-dark': Color.$IN700,
  'test-alt-fg': new CssVariableRef('test-alt-default'),
  'test-alt-fg-emphasis': new CssVariableRef('test-alt-emphasis'),
  'test-alt-toggle-bg': Color['$N900-D'],
  'fg-onTest-alt': Color.$N800,
  'fg-onTest-alt-dark': Color.$N00,
  'fg-onTest-alt-toggle': Color.$N00,
  'test-alt-border': new CssVariableRef('test-alt-muted'),
  'test-alt-border-emphasis': new CssVariableRef('test-alt-emphasis'),

  'info-default': Color.$B300,
  'info-emphasis': Color.$B200,
  'info-tint': Color['$B800-D'],
  'info-subtle': Color['$B700-D'],
  'info-muted': Color.$B500,
  'info-fg': new CssVariableRef('info-default'),
  'info-fg-emphasis': new CssVariableRef('info-emphasis'),
  'fg-onInfo': Color.$N800,
  'info-border': new CssVariableRef('info-muted'),
  'info-border-emphasis': new CssVariableRef('info-emphasis'),

  'success-default': Color.$G400,
  'success-emphasis': Color.$G300,
  'success-tint': Color['$G800-D'],
  'success-subtle': Color['$G700-D'],
  'success-muted': Color.$G600,
  'success-fg': new CssVariableRef('success-default'),
  'success-fg-emphasis': new CssVariableRef('success-emphasis'),
  'fg-onSuccess': Color.$N800,
  'success-border': new CssVariableRef('success-muted'),
  'success-border-emphasis': new CssVariableRef('success-emphasis'),

  'warning-default': Color.$O300,
  'warning-emphasis': Color.$O200,
  'warning-tint': Color['$O800-D'],
  'warning-subtle': Color['$O700-D'],
  'warning-muted': Color.$O500,
  'warning-fg': new CssVariableRef('warning-default'),
  'warning-fg-emphasis': new CssVariableRef('warning-emphasis'),
  'fg-onWarning': Color.$N800,
  'warning-border': new CssVariableRef('warning-muted'),
  'warning-border-emphasis': new CssVariableRef('warning-emphasis'),

  'error-default': Color.$R300,
  'error-emphasis': Color.$R200,
  'error-tint': Color['$R800-D'],
  'error-subtle': Color['$R700-D'],
  'error-muted': Color.$R500,
  'error-fg': new CssVariableRef('error-default'),
  'error-fg-emphasis': new CssVariableRef('error-emphasis'),
  'fg-onError': Color.$N800,
  'error-border': new CssVariableRef('error-muted'),
  'error-border-emphasis': new CssVariableRef('error-emphasis'),

  'notice-default': Color.$SL300,
  'notice-emphasis': Color.$SL200,
  'notice-tint': Color.$SL800,
  'notice-subtle': Color.$SL700,
  'notice-muted': Color.$SL500,
  'notice-fg': new CssVariableRef('notice-default'),
  'notice-fg-emphasis': new CssVariableRef('notice-emphasis'),
  'fg-onNotice': Color.$N800,
  'notice-border': new CssVariableRef('notice-muted'),
  'notice-border-emphasis': new CssVariableRef('notice-emphasis'),

  'env-dev-default': Color.$DEV300,
  'env-dev-emphasis': Color.$DEV200,
  'env-dev-tint': Color['$DEV800-D'],
  'env-dev-subtle': Color['$DEV700-D'],
  'env-dev-muted': Color.$DEV600,
  'env-dev-fg': Color.$DEV300,
  'env-dev-fg-emphasis': Color.$DEV200,
  'fg-onEnv-dev': Color.$N800,
  'env-dev-border': Color.$DEV600,
  'env-dev-border-emphasis': Color.$DEV200,

  'env-test-default': Color.$TEST400,
  'env-test-emphasis': Color.$TEST300,
  'env-test-tint': Color['$TEST800-D'],
  'env-test-subtle': Color['$TEST700-D'],
  'env-test-muted': Color.$TEST600,
  'env-test-fg': Color.$TEST400,
  'env-test-fg-emphasis': Color.$TEST300,
  'fg-onEnv-test': Color.$N800,
  'env-test-border': Color.$TEST600,
  'env-test-border-emphasis': Color.$TEST300,

  'env-prod-default': Color.$PROD300,
  'env-prod-emphasis': Color.$PROD200,
  'env-prod-tint': Color['$PROD800-D'],
  'env-prod-subtle': Color['$PROD700-D'],
  'env-prod-muted': Color.$PROD600,
  'env-prod-fg': Color.$PROD300,
  'env-prod-fg-emphasis': Color.$PROD200,
  'fg-onEnv-prod': Color.$N800,
  'env-prod-border': Color.$PROD600,
  'env-prod-border-emphasis': Color.$PROD200,

  'rust-default': Color.$RS300,
  'rust-emphasis': Color.$RS200,
  'rust-tint': Color['$RS800-D'],
  'rust-subtle': Color['$RS700-D'],
  'rust-muted': Color.$RS500,
  'rust-fg': Color.$RS300,
  'rust-fg-emphasis': Color.$RS200,
  'fg-onRust': Color.$N800,
  'rust-border': Color.$RS500,
  'rust-border-emphasis': Color.$RS200,

  'olive-default': Color.$OL300,
  'olive-emphasis': Color.$OL200,
  'olive-tint': Color.$OL800,
  'olive-subtle': Color.$OL700,
  'olive-muted': Color.$OL500,
  'olive-fg': Color.$OL300,
  'olive-fg-emphasis': Color.$OL200,
  'fg-onOlive': Color.$N800,
  'olive-border': Color.$OL500,
  'olive-border-emphasis': Color.$OL200,

  'plum-default': Color.$PL300,
  'plum-emphasis': Color.$PL200,
  'plum-tint': Color['$PL800-D'],
  'plum-subtle': Color['$PL700-D'],
  'plum-muted': Color.$PL500,
  'plum-fg': Color.$PL300,
  'plum-fg-emphasis': Color.$PL200,
  'fg-onPlum': Color.$N800,
  'plum-border': Color.$PL500,
  'plum-border-emphasis': Color.$PL200,

  'violet-default': Color.$VI300,
  'violet-emphasis': Color.$VI200,
  'violet-tint': Color['$VI800-D'],
  'violet-subtle': Color['$VI700-D'],
  'violet-muted': Color.$VI500,
  'violet-fg': Color.$VI300,
  'violet-fg-emphasis': Color.$VI200,
  'fg-onViolet': Color.$N800,
  'violet-border': Color.$VI500,
  'violet-border-emphasis': Color.$VI200,

  'purple-default': Color.$P300,
  'purple-emphasis': Color.$P200,
  'purple-tint': Color['$P800-D'],
  'purple-subtle': Color['$P700-D'],
  'purple-muted': Color.$P500,
  'purple-fg': Color.$P300,
  'purple-fg-emphasis': Color.$P200,
  'fg-onPurple': Color.$N800,
  'purple-border': Color.$P500,
  'purple-border-emphasis': Color.$P200,

  'gold-default': Color.$GD300,
  'gold-emphasis': Color.$GD200,
  'gold-tint': Color['$GD800-D'],
  'gold-subtle': Color['$GD700-D'],
  'gold-muted': Color.$GD500,
  'gold-fg': Color.$GD300,
  'gold-fg-emphasis': Color.$GD200,
  'fg-onGold': Color.$N800,
  'gold-border': Color.$GD500,
  'gold-border-emphasis': Color.$GD200,

  'brown-default': Color.$BR300,
  'brown-emphasis': Color.$BR200,
  'brown-tint': Color.$BR800,
  'brown-subtle': Color.$BR700,
  'brown-muted': Color.$BR500,
  'brown-fg': Color.$BR300,
  'brown-fg-emphasis': Color.$BR200,
  'fg-onBrown': Color.$N800,
  'brown-border': Color.$BR500,
  'brown-border-emphasis': Color.$BR200,

  'forest-default': Color.$FR300,
  'forest-emphasis': Color.$FR200,
  'forest-tint': Color.$FR800,
  'forest-subtle': Color.$FR700,
  'forest-muted': Color.$FR500,
  'forest-fg': Color.$FR300,
  'forest-fg-emphasis': Color.$FR200,
  'fg-onForest': Color.$N800,
  'forest-border': Color.$FR500,
  'forest-border-emphasis': Color.$FR200,

  'teal-default': Color.$T300,
  'teal-emphasis': Color.$T200,
  'teal-tint': Color['$T800-D'],
  'teal-subtle': Color['$T700-D'],
  'teal-muted': Color.$T500,
  'teal-fg': Color.$T300,
  'teal-fg-emphasis': Color.$T200,
  'fg-onTeal': Color.$N800,
  'teal-border': Color.$T500,
  'teal-border-emphasis': Color.$T200,

  'blue-default': Color.$B300,
  'blue-emphasis': Color.$B200,
  'blue-tint': Color['$B800-D'],
  'blue-subtle': Color['$B700-D'],
  'blue-muted': Color.$B500,
  'blue-fg': Color.$B300,
  'blue-fg-emphasis': Color.$B200,
  'fg-onBlue': Color.$N800,
  'blue-border': Color.$B500,
  'blue-border-emphasis': Color.$B200,

  'indigo-default': Color.$IN300,
  'indigo-emphasis': Color.$IN200,
  'indigo-tint': Color.$IN800,
  'indigo-subtle': Color.$IN700,
  'indigo-muted': Color.$IN500,
  'indigo-fg': Color.$IN300,
  'indigo-fg-emphasis': Color.$IN200,
  'fg-onIndigo': Color.$N800,
  'indigo-border': Color.$IN500,
  'indigo-border-emphasis': Color.$IN200,

  'green-default': Color.$G400,
  'green-emphasis': Color.$G300,
  'green-tint': Color['$G800-D'],
  'green-subtle': Color['$G700-D'],
  'green-muted': Color.$G600,
  'green-fg': Color.$G400,
  'green-fg-emphasis': Color.$G300,
  'fg-onGreen': Color.$N800,
  'green-border': Color.$G600,
  'green-border-emphasis': Color.$G300,

  'orange-default': Color.$O300,
  'orange-emphasis': Color.$O200,
  'orange-tint': Color['$O800-D'],
  'orange-subtle': Color['$O700-D'],
  'orange-muted': Color.$O500,
  'orange-fg': Color.$O300,
  'orange-fg-emphasis': Color.$O200,
  'fg-onOrange': Color.$N800,
  'orange-border': Color.$O500,
  'orange-border-emphasis': Color.$O200,

  'red-default': Color.$R300,
  'red-emphasis': Color.$R200,
  'red-tint': Color['$R800-D'],
  'red-subtle': Color['$R700-D'],
  'red-muted': Color.$R500,
  'red-fg': Color.$R300,
  'red-fg-emphasis': Color.$R200,
  'fg-onRed': Color.$N800,
  'red-border': Color.$R500,
  'red-border-emphasis': Color.$R200,

  'slate-default': Color.$SL300,
  'slate-emphasis': Color.$SL200,
  'slate-tint': Color.$SL800,
  'slate-subtle': Color.$SL700,
  'slate-muted': Color.$SL500,
  'slate-fg': Color.$SL300,
  'slate-fg-emphasis': Color.$SL200,
  'fg-onSlate': Color.$N800,
  'slate-border': Color.$SL500,
  'slate-border-emphasis': Color.$SL200,

  'mktg-neutral-default': Color['$BR-N100'],
  'mktg-neutral-emphasis': Color['$BR-N025'],
  'fg-onMktg-neutral': Color['$BR-N400'],

  'utility-bg-teal': Color.$T200,
  'utility-bg-gold': Color.$GD200,
  'utility-bg-blue': Color.$B200,
  'utility-bg-orange': Color.$O200,
  'utility-bg-red': Color.$R200,
  'utility-bg-slate': Color.$SL200,
  'utility-bg-neutral': Color.$N100,
  'utility-fg-teal': Color.$T600,
  'utility-fg-gold': Color.$GD600,
  'utility-fg-blue': Color.$B600,
  'utility-fg-orange': Color.$O600,
  'utility-fg-red': Color.$R600,
  'utility-fg-slate': Color.$SL600,
  'utility-fg-neutral': Color.$N500,
  'utility-border': Color.$N200,

  'ai-default': Color.$DEV300,
  'ai-emphasis': Color.$DEV200,
  'ai-tint': Color['$DEV800-D'],
  'ai-subtle': Color['$DEV700-D'],
  'ai-muted': Color.$DEV500,
  'ai-fg': Color.$DEV300,
  'ai-fg-emphasis': Color.$DEV200,
  'fg-onAi': Color.$N800,
  'ai-border': Color.$DEV500,
  'ai-border-emphasis': Color.$DEV200,

  'ai-alt-default': Color.$AQ300,
  'ai-alt-emphasis': Color.$AQ200,
  'ai-alt-tint': Color['$AQ800-D'],
  'ai-alt-subtle': Color['$AQ700-D'],
  'ai-alt-muted': Color.$AQ500,
  'ai-alt-fg': Color.$AQ300,
  'ai-alt-fg-emphasis': Color.$AQ200,
  'fg-onAi-alt': Color.$N800,
  'ai-alt-border': Color.$AQ500,
  'ai-alt-border-emphasis': Color.$AQ200,

  'copilot-primary': Color.$DEV400,
  'copilot-secondary': Color.$DEV500,
  'copilot-alt': Color.$AQ300,

  'chart-teal-muted': Color.$T500,
  'chart-teal-subtle': Color['$T700-D'],
  'chart-teal-tint': Color['$T800-D'],
  'chart-teal-fg': Color.$T300,
  'chart-teal-border': Color.$T500,
  'chart-blue-muted': Color.$B500,
  'chart-blue-subtle': Color['$B700-D'],
  'chart-blue-tint': Color['$B800-D'],
  'chart-blue-fg': Color.$B300,
  'chart-blue-border': Color.$B500,
  'chart-violet-muted': Color.$VI500,
  'chart-violet-subtle': Color['$VI700-D'],
  'chart-violet-tint': Color['$VI800-D'],
  'chart-violet-fg': Color.$VI300,
  'chart-violet-border': Color.$VI500,
  'chart-red-muted': Color.$R500,
  'chart-red-subtle': Color['$R700-D'],
  'chart-red-tint': Color['$R800-D'],
  'chart-red-fg': Color.$R300,
  'chart-red-border': Color.$R500,
  'chart-green-muted': Color.$G600,
  'chart-green-subtle': Color['$G700-D'],
  'chart-green-tint': Color['$G800-D'],
  'chart-green-fg': Color.$G400,
  'chart-green-border': Color.$G600,
  'chart-brown-muted': Color.$BR500,
  'chart-brown-subtle': Color.$BR700,
  'chart-brown-tint': Color.$BR800,
  'chart-brown-fg': Color.$BR300,
  'chart-brown-border': Color.$BR500,
  'chart-orange-muted': Color.$O500,
  'chart-orange-subtle': Color['$O700-D'],
  'chart-orange-tint': Color['$O800-D'],
  'chart-orange-fg': Color.$O300,
  'chart-orange-border': Color.$O500,
  'chart-gold-muted': Color.$GD500,
  'chart-gold-subtle': Color['$GD700-D'],
  'chart-gold-tint': Color['$GD800-D'],
  'chart-gold-fg': Color.$GD300,
  'chart-gold-border': Color.$GD500,
  'chart-indigo-muted': Color.$IN500,
  'chart-indigo-subtle': Color.$IN700,
  'chart-indigo-tint': Color.$IN800,
  'chart-indigo-fg': Color.$IN300,
  'chart-indigo-border': Color.$IN500,
  'chart-plum-muted': Color.$PL500,
  'chart-plum-subtle': Color['$PL700-D'],
  'chart-plum-tint': Color['$PL800-D'],
  'chart-plum-fg': Color.$PL300,
  'chart-plum-border': Color.$PL500,
  'chart-slate-muted': Color.$SL500,
  'chart-slate-subtle': Color.$SL700,
  'chart-slate-tint': Color.$SL800,
  'chart-slate-fg': Color.$SL300,
  'chart-slate-border': Color.$SL500,
  'chart-rose-muted': Color.$PROD600,
  'chart-rose-subtle': Color['$PROD700-D'],
  'chart-rose-tint': Color['$PROD800-D'],
  'chart-rose-fg': Color.$PROD300,
  'chart-rose-border': Color.$PROD600,
  'chart-yellow-muted': Color.$TEST600,
  'chart-yellow-subtle': Color['$TEST700-D'],
  'chart-yellow-tint': Color['$TEST800-D'],
  'chart-yellow-fg': Color.$TEST400,
  'chart-yellow-border': Color.$TEST600,
  'chart-cobalt-muted': Color.$DEV600,
  'chart-cobalt-subtle': Color['$DEV700-D'],
  'chart-cobalt-tint': Color['$DEV800-D'],
  'chart-cobalt-fg': Color.$DEV300,
  'chart-cobalt-border': Color.$DEV600,

  'usage-dashboard-tint': Color['$DEV800-D'],
  'usage-dashboard-alt-tint': Color['$AQ800-D'],
  'usage-dashboard-alt-border': Color.$AQ500,
};
